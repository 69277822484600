




























































































import Vue from 'vue';
import { triggerExcel, updateTargetBidPrice } from '@/api/project';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    overPriceInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      targetBidPrice: '',
    };
  },
  computed: {
    ...mapGetters(['projectSequenceNbr']),
    targetPrice() {
      let price = '';
      if (
        this.overPriceInfo.targetPrice <
        Number(
          this.overPriceInfo.downAdjust -
            this.overPriceInfo.materialPriceTotal * 0.7
        ).toFixed(2)
      ) {
        price = Number(
          this.overPriceInfo.downAdjust -
            this.overPriceInfo.materialPriceTotal * 0.7
        ).toFixed(2);
      } else {
        price = this.overPriceInfo.targetPrice;
      }
      return price;
    },
  },
  methods: {
    ...mapMutations(['SET_IS_THERE_REPLACE']),
    ...mapActions(['getGenerateListStatus']),
    cancel() {
      this.targetBidPrice = '';
      this.SET_IS_THERE_REPLACE(false);
      this.$emit('update:visible', false);
      this.$emit('endPrice')
      this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', true);
      triggerExcel(this.projectSequenceNbr).then((res) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.getGenerateListStatus();
        }
      });
    },
    /**
     *
     * @param type 1 取接近目标投标价值*0.5  2 手动输入值
     */
    updateTargetBidPrice(type) {
      let price;
      if (type === 1) {
        price = this.targetPrice;
      } else {
        if (!this.targetBidPrice) {
          this.$message.error('请重新输入目标价');
          return;
        }
        if (
          this.targetBidPrice < this.overPriceInfo.downAdjust ||
          this.targetBidPrice > this.overPriceInfo.upAdjust
        ) {
          this.$message.error('请将您的目标价输入到推荐范围值内');
          return;
        }
        price = this.targetBidPrice;
      }

      let apiData = {
        constructId: this.projectSequenceNbr,
        targetBidPrice: price,
      };
      console.log('apiData', apiData);
      updateTargetBidPrice(apiData).then((res) => {
        console.log('res', res);
        if (res.status === 200 && res.result) {
          this.targetBidPrice = '';
          this.$emit('update:visible', false);
          this.$emit('startPrice');
        }
      });
    },
    proving() {
      this.targetBidPrice = (this.targetBidPrice.match(
        /\d+(\.\d{0,2}|100)?/
      ) || [''])[0];
    },
  },
});
